@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
// @import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);



/* Font Smoothing */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: $font-weight-normal;
}

.cr-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}
.cr-sidebar .nav .nav-link {
    color: #f8f9fa;
    font-size: 13px;
    line-height: 24px;
}
.nav-link {
    display: block;
    padding: 0.5rem 0.5rem;
}